import React, { useState } from 'react';
import { handleDownload } from '../Conversion/Base64';
import '../css/Base64.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Base64ToImage = () => {
  const [base64, setBase64] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const handleConvert = () => {
    if (base64.trim() === '') {
      toast.error('Please enter a valid Base64 string');
      return;
    }

    try {
      const isValidBase64 = base64.startsWith('data:image/') && base64.includes(';base64,');
      if (!isValidBase64) {
        toast.error('Invalid Base64 image string');
        return;
      }
      setImageSrc(base64);
      toast.success('Image converted successfully');
    } catch (error) {
      toast.error('Error converting Base64 to image');
    }
  };

  const handleDownloadWrapper = () => handleDownload(imageSrc, toast);

  return (
    <div className="base64-to-image">
      <Header />

      <div
        className="w3-container w3-light-grey"
        style={{ padding: '95px 66px' }}
        id="contact1"
      >
        <h1 className="title-heading" >
          Convert Base64 to Image Easily
        </h1>

        <p className="title-subheading" >
        Transform Base64 code into images effortlessly with Data Beautifier's Free Base64 to Image Conversion Online Tool. Our Best Base64 to Image Conversion Online Tool ensures accurate and quick results, making it ideal for developers and designers. The Simple Base64 to Image Converter Tool is user-friendly, requiring no technical expertise—just paste your Base64 code and get the image instantly. With the Easy Base64 to Image Converter Tool, you can handle conversions with ease, saving time and effort. Accessible anytime and completely free, Data Beautifier is your go-to platform for seamless Base64 to image conversion. Try it today!
        </p>

        <div className="w3-row">
          <div className="w3-col m5">
            <h1>Base64 to Image Conversion</h1>
            <textarea
              value={base64}
              onChange={(e) => setBase64(e.target.value)}
              rows="18"
              placeholder="Enter Base64 string here"
              className="base64-textarea"
            />
          </div>
          <div
            className="w3-col m2"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <button className="mar-top-80" onClick={handleConvert}>
              Convert to Image
            </button>
          </div>
          <div className="w3-col m5">
            <h2>Image Preview</h2>
            {imageSrc && (
              <div className="image-preview-section">
                <img src={imageSrc} alt="Preview" className="preview-image" />
                <button className="download-button" onClick={handleDownloadWrapper}>
                  Download
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Base64ToImage;