// src/HtmlFormatter.js
import React, { useState, useRef } from "react";
import { formatHtml } from "../Conversion/HtmlFormat";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { copyToClipboard, zoomIn, zoomOut, openFullscreen, handlehtmlDownload } from '../Helpers/CommonHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';

const HtmlFormatter = () => {
    const [inputHtml, setInputHtml] = useState("");
    const [formattedHtml, setFormattedHtml] = useState("");
    const [fontSize, setFontSize] = useState(14);
    const outputRef = useRef(null);

    const handleFormat = () => {
        const formatted = formatHtml(inputHtml);
        setFormattedHtml(formatted);
    };

    const handleCopy = () => {
        copyToClipboard(formattedHtml);
    };

    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
                <h1 className="title-heading">
                Format HTML Code Seamlessly
                </h1>
                <p className="title-subheading">
                Beautify your HTML code effortlessly with Data Beautifier's Free HTML Formatter Online Tool. Our Best HTML Formatter Online Tool enhances code readability by organizing and structuring your HTML files efficiently. Whether you're a seasoned developer or a beginner, our Simple HTML Formatter Tool is designed for ease of use, requiring no prior expertise. With the Easy HTML Formatter Tool, you can format messy HTML code in seconds, ensuring clean and professional results every time. Available 24/7 and free of charge, Data Beautifier is the ultimate solution for optimizing your HTML formatting needs. Try it today for hassle-free results!
                </p>
                <div className="w3-row">
                    <div className="w3-col m5">
                        <textarea
                            className="w3-input w3-border textarea"
                            rows="28"
                            placeholder="Enter your HTML here..."
                            value={inputHtml}
                            onChange={(e) => setInputHtml(e.target.value)}
                        />
                    </div>
                    <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleFormat} disabled={!inputHtml.trim()} className="mar-top-80">
                            Format HTML
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <div style={{ position: 'relative' }}>
                            <textarea
                                className="w3-input w3-border output-textarea textarea"
                                rows="30"
                                value={formattedHtml}
                                readOnly
                                style={{ fontSize: `${fontSize}px`, width: '100%' }}
                                placeholder="Formatted HTML will appear here..."
                                ref={outputRef}
                            />
                            <div style={{ position: 'absolute', top: '65px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={handleCopy} title="Copy To Clipboard">
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomIn(setFontSize)} title="Zoom In">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomOut(setFontSize)} title="Zoom Out">
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => openFullscreen(outputRef)} title="View Full Screen">
                                    <FontAwesomeIcon icon={faExpand} />
                                </button>
                                <button className="w3-button w3-blue" style={{ marginTop: '5px' }} onClick={() => handlehtmlDownload(formattedHtml)} title="Download File">
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HtmlFormatter;
