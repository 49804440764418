import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { 
    faFacebook, 
    faInstagram, 
    faSnapchat, 
    faPinterest, 
    faTwitter, 
    faLinkedin 
  } from '@fortawesome/free-brands-svg-icons';

  
const Footer = () => {
  const navigate = useNavigate();
  const handleAboutClick = () => navigate('/about');
  const handleFAQClick = () => navigate('/faq');
  const handlePrivacyClick = () => navigate('/privacy');
  const handleBase64ToImg = () => navigate('/base64-to-image-conversion');
  const HandleImgToBase64 = () => navigate('/image-to-base64-conversion');
  const handleJsonminifier = () => navigate('/json-minifier');
  const handleXmlminifier = () => navigate('/xml-minifier');
  const handleJsontoxml = () => navigate('/json-to-xml-convertor');
  const handleXmltojson = () => navigate('/xml-to-json-convertor');
  const handleXmlformatter = () => navigate('/xml-formatter');
  const handleHtmlformatter = () => navigate('/html-formatter');
  // const handleBlogClick = () => navigate('/blog');
  // const handleHexColorClick = () => navigate('/hexcolorcode');
  // const handleBase64Click = () => navigate('/Base64Conversion');

  return (
    <div>
      {/* Navbar */}
      <footer className="w3-center w3-black w3-padding-64 footer">
        <div className="w3-container" style={{ padding: '64px 66px' }} id="contact1">
          <div>
          <div className="w3-col m3">
              <p className="ul-title">Convertor</p>
              <ul>
                <li onClick={handleJsontoxml} className='link'>JSON to XML</li>
                <li onClick={handleXmltojson} className='link'>XML to Json</li>
              </ul>
            </div>
            <div className="w3-col m3">
              <p className="ul-title">Minifier</p>
              <ul>
                <li onClick={handleJsonminifier} className='link'>Json Minifier</li>
                <li onClick={handleXmlminifier} className='link'>Xml Minifier</li>
              </ul>
            </div>
            <div className="w3-col m3">
              <p className="ul-title">Formatter</p>
              <ul>
                <li onClick={handleXmlformatter} className='link'>XML Formatter</li>
                <li onClick={handleHtmlformatter} className='link'>HTML Formatter</li>
              </ul>
            </div>
            <div className="w3-col m3">
              <p className="ul-title">Base 64 Tools</p>
              <ul>
                <li onClick={handleBase64ToImg} className='link'>Base64 To Image</li>
                <li onClick={HandleImgToBase64} className='link'>Image To Base64</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w3-xlarge w3-section">
          <a className='footerlink' href="http://facebook.com"><FontAwesomeIcon icon={faFacebook} /></a>
          <a className='footerlink' href="http://instagram.com"><FontAwesomeIcon icon={faInstagram} /></a>
          {/* <a className='footerlink' href="http://snapchat.com"><FontAwesomeIcon icon={faSnapchat} /></a> */}
          <a className='footerlink' href="http://pinterest.com"><FontAwesomeIcon icon={faPinterest} /></a>
          <a className='footerlink' href="http://twitter.com"><FontAwesomeIcon icon={faTwitter} /></a>
          <a className='footerlink' href="http://linkedin.com"><FontAwesomeIcon icon={faLinkedin} /></a>
        </div>
        <p>
          Copyright © Data Beautify 2024 V 1.0
          | <a onClick={handlePrivacyClick} className='footerlink underline'>Privacy Policy</a>  
          | <a onClick={handleAboutClick} className='footerlink underline'>About</a>  
          | <a onClick={handleFAQClick} className='footerlink underline'>FAQ</a>  
          {/* | <a onClick={handleBlogClick}  className='footerlink underline'>Blog</a>   */}
          | <a onClick={handleBase64ToImg} className='footerlink underline'>Base64 to Image</a>  
          {/* | <a onClick={handleHexColorClick}  className='footerlink underline'>Hex Color Codes</a>   */}
        </p>
      </footer>
    </div>
  );
};

export default Footer;