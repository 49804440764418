import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { minifyData } from '../Conversion/Minifiers';
import { copyToClipboard, handleDownload, zoomIn, zoomOut, openFullscreen } from '../Helpers/CommonHelpers';

const MinifierPage = ({ type }) => {
  const [inputData, setInputData] = useState('');
  const [outputData, setOutputData] = useState('');
  const [isError, setIsError] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const inputRef = useRef(null);
  const outputRef = useRef(null);

  useEffect(() => {
    setInputData('');
    setOutputData('');
    setIsError(false);
  }, [type]);


  const handleConversion = () => {
    const { minified, isError } = minifyData(type, inputData);
    setOutputData(minified);
    setIsError(isError);
  };

  const handleCopy = () => {
    copyToClipboard(outputData);
  };

  return (
    <div>
      <Header />
      <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact2">
        <h1 className="title-heading">
          {type === 'json' ? 'Minify JSON Instantly' : 'Minify XML Effortlessly'}
        </h1>
        <p className="title-subheading">
          {type === 'json' ? 
          (<><p>Streamline your JSON files with Data Beautifier's Free JSON Minifier Online Tool. Our Best JSON Minifier Online Tool is designed to compress your JSON data, removing unnecessary spaces and formatting while maintaining accuracy. The Simple JSON Minifier Tool is user-friendly, making it ideal for developers, data analysts, or anyone handling JSON files. With our Easy JSON Minifier Tool, you can optimize your files for faster processing and reduced file size in just a few clicks. Enjoy quick, reliable results for free on Data Beautifier. Simplify your data management today with the ultimate JSON minification tool!</p></>) 
          : (<><p>Optimize your XML files with ease using Data Beautifier's Free XML Minifier Online Tool. Our Best XML Minifier Online Tool compresses your XML data by removing unnecessary spaces, line breaks, and formatting, ensuring a smaller, faster, and cleaner file. Designed for simplicity, our Simple XML Minifier Tool requires no technical expertise, making it perfect for developers and data managers alike. With the Easy XML Minifier Tool, you can enhance your workflow in just a few clicks. Accessible anytime and completely free, Data Beautifier provides reliable results for all your XML compression needs. Try it today!</p></>)}
        </p>
        <div className="w3-row">
          <div className="w3-col m5">
            <div className="textarea-container">
              <textarea
                className="w3-input w3-border textarea"
                rows="30"
                placeholder={`Input ${type === 'json' ? 'JSON' : 'XML'}`}
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                ref={inputRef}
                style={{ width: '100%', marginBottom: '20px' }}
              />
            </div>
          </div>
          <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <button
              className="w3-button w3-blue w3-margin-top"
              style={{ width: '50%', margin: '20px' }}
              onClick={handleConversion}
            >
              Convert
            </button>
          </div>
          <div className="w3-col m5">
            <div style={{ position: 'relative' }}>
              <div className="textarea-container">
                <textarea
                  className="w3-input w3-border output-textarea textarea"
                  rows="32"
                  placeholder="Output"
                  value={outputData}
                  readOnly
                  style={{ color: isError ? 'red' : 'black', fontSize: `${fontSize}px`, width: '100%' }}
                  ref={outputRef}
                />
              </div>
              <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={handleCopy} title="Copy To Clipboard">
                  <FontAwesomeIcon icon={faCopy} />
                </button>
                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomIn(setFontSize)} title="Zoom In">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomOut(setFontSize)} title="Zoom Out">
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => openFullscreen(outputRef)} title="View Full Screen">
                  <FontAwesomeIcon icon={faExpand} />
                </button>
                <button className="w3-button w3-blue" style={{ marginTop: '5px' }} onClick={() => handleDownload(outputData, type)} title="Download File">
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MinifierPage;
