import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleManager = () => {
  const location = useLocation();

  const routeTitles = {
    '/about': 'About Us - Databeautifier',
    '/faq': 'FAQ - Databeautifier',
    '/privacy': 'Privacy Policy - Databeautifier',
    '/base64-to-image-conversion': 'Base64 to Image Conversion - Databeautifier',
    '/image-to-base64-conversion': 'Image to Base64 Conversion - Databeautifier',
    '/json-minifier': 'JSON Minifier - Databeautifier',
    '/xml-minifier': 'XML Minifier - Databeautifier',
    '/json-to-xml-convertor': 'JSON to XML Convertor - Databeautifier',
    '/xml-to-json-convertor': 'XML to JSON Convertor - Databeautifier',
    '/xml-formatter': 'XML Formatter - Databeautifier',
    '/html-formatter': 'HTML Formatter - Databeautifier',
  };

  useEffect(() => {
    const title = routeTitles[location.pathname] || 'Databeautifier';
    document.title = title;
  }, [location]);

  return null;
};

export default TitleManager;
