export const convertJsonXmlData = (type, inputData) => {
  let convertedData = '';
  let isError = false;

  try {
      if (type === 'jsontoxml') {
          // Convert JSON to XML
          const json = JSON.parse(inputData);
          convertedData = jsonToXml(json);
      } else if (type === 'xmltojson') {
          // Convert XML to JSON
          convertedData = JSON.stringify(xmlToJson(inputData), null, 4);
      }
  } catch (error) {
      isError = true;
      convertedData = 'Invalid data format.';
  }

  return { convertedData, isError };
};

// Helper function to convert JSON to XML
const jsonToXml = (json, indent = '') => {
  let xml = '';

  if (Array.isArray(json)) {
      json.forEach((item) => {
          xml += `${jsonToXml(item, indent)}`;
      });
  } else if (typeof json === 'object' && json !== null) {
      for (let key in json) {
          if (json.hasOwnProperty(key)) {
              const value = json[key];

              if (key.startsWith('_')) {
                  xml = xml.replace('>', ` ${key.slice(1)}="${value}">`);
              } else if (Array.isArray(value)) {
                  xml += `${indent}<${key}>\n${jsonToXml(value, indent + '  ')}${indent}</${key}>\n`;
              } else if (typeof value === 'object') {
                  xml += `${indent}<${key}>\n${jsonToXml(value, indent + '  ')}${indent}</${key}>\n`;
              } else {
                  xml += `${indent}<${key}>${value}</${key}>\n`;
              }
          }
      }
  }
  return xml;
};

// Helper function to convert XML to JSON
const xmlToJson = (xml) => {
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(xml, 'text/xml');
  return xmlToJsonHelper(xmlDoc.documentElement);
};

const xmlToJsonHelper = (node) => {
  let obj = {};

  if (node.nodeType === 1) { // element node
      // Process attributes
      if (node.attributes.length > 0) {
          for (let j = 0; j < node.attributes.length; j++) {
              let attribute = node.attributes.item(j);
              obj[`_${attribute.nodeName}`] = attribute.nodeValue;
          }
      }
  }

  if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
          let child = node.childNodes.item(i);

          if (child.nodeType === 3) { // text node
              const textValue = child.nodeValue.trim();
              if (textValue) {
                  // If the node has no other keys, assign the text directly
                  if (Object.keys(obj).length === 0) {
                      return textValue;
                  }
              }
          } else if (child.nodeType === 1) { // element node
              const childName = child.nodeName;
              const childObj = xmlToJsonHelper(child);

              if (!obj[childName]) {
                  obj[childName] = childObj;
              } else if (Array.isArray(obj[childName])) {
                  obj[childName].push(childObj);
              } else {
                  obj[childName] = [obj[childName], childObj];
              }
          }
      }
  }

  return obj;
};
