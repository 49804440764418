  import { toast } from 'react-toastify';

  // Function to copy text to the clipboard and show a toast
  export const copyToClipboard = (text) => {
    if (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          toast.success('Output copied to clipboard!');
        })
        .catch((error) => {
          console.error('Clipboard copy failed: ', error);
          toast.error('Failed to copy to clipboard.');
        });
    } else {
      toast.error('No output to copy!', { className: 'w3-red' });
    }
  };

  // Function to handle file download
export const handleDownload = (outputData) => {
  if (outputData) {
    const blob = new Blob([outputData], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.json';
    link.click();
    toast.success('File downloaded successfully!');
  } else {
    toast.error('No output data to download!', { className: 'w3-red' });
  }
};

  // Function to handle html file download
export const handlehtmlDownload = (outputData) => {
  if (outputData) {
    const blob = new Blob([outputData], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);    
    link.download = 'output.html';    
    link.click();    
    toast.success('File downloaded successfully!');
  } else {
    toast.error('No output data to download!', { className: 'w3-red' });
  }
};

export const handlexmlDownload = (outputData) => {
  if (outputData) {
    // Create a blob with the XML content
    const blob = new Blob([outputData], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.xml';
    link.click();
    toast.success('File downloaded successfully!');
  } else {
    toast.error('No output data to download!', { className: 'w3-red' });
  }
};



// Function to zoom in
export const zoomIn = (setFontSize) => {
  setFontSize((prevSize) => prevSize + 2);
};

// Function to zoom out
export const zoomOut = (setFontSize) => {
  setFontSize((prevSize) => Math.max(prevSize - 2, 8));
};

// Function to open in fullscreen
export const openFullscreen = () => {
  const elem = document.querySelector('.output-textarea');
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};